@tailwind base;
@tailwind components;
@tailwind utilities;


.header{
  @apply gap-5  md:gap-0 p-10 md:p-0 flex md:h-28 bg-foreground text-white justify-around flex-col md:flex-row items-center font-bold text-center sticky top-0 z-50 transition-all duration-300 ease-in-out
}

.header .toggle-menu{
  @apply md:hidden block
}

.header .nav.mobile{
  @apply md:hidden flex flex-col gap-5
}

.header .nav{
  @apply md:flex hidden md:flex-row md:gap-5 xl:gap-20
}

.menu-item{
  @apply hover:border-b-4 hover:border-accent p-3 ease-in-out duration-300 text-base
}

.submenu-item{
  @apply hover:border-b-4 hover:border-accent p-3 ease-in-out duration-300
}