@tailwind base;
@tailwind components;
@tailwind utilities;


h1, h2, h3, h4, h5, h6 {
  @apply font-bold text-primary;
}

h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

h4 {
  @apply text-xl;
}

h5 {
  @apply text-lg;
}

h6 {
  @apply text-base;
}

.footer a{
  @apply hover:underline;
}

/* @media (prefers-color-scheme: dark) { */
:root {
  /* --background: #ffffff; */
  --background: #294061;
  /* --foreground: #353434; */
  --foreground: #1e1c1c;
  --primary: #191818;
  --secondary: #5ac95a;
  --accent: #ee8609;
  /* --accent: #ffcc03; */
  
}
/* } */


body {
  color: var(--primary);
  /* background: var(--background); */
  background : #ffffff;
  font-family: Poppins, sans-serif;
}
