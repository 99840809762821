@tailwind base;
@tailwind components;
@tailwind utilities;

.footer{
  @apply bg-foreground text-white gap-5 py-5 px-5 xl:px-20 xl:py-10 flex flex-col md:grid md:grid-cols-3 xl:grid xl:grid-cols-3 justify-items-center items-center
}

.footer-social
{
  @apply flex flex-row gap-5 items-center
}

